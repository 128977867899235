class Letter {
    constructor(letter, isWhite) {
        this.letter = " ";
        this.letterOriginal = letter;
        this.isWhite = isWhite;
        this.startAnimationTime = window.performance.now();
        this.animationDuration = Math.ceil(100 * (Math.random() * 15 + 25));
        this.animationProgress = 0;
        this.randNumAnimateFrom = Math.ceil(Math.random() * 10 + 3);
        this.characterChoices = "ABCDEFGHIJKLMNOPQRSTUVWXYZ ";
        this.randCharacters = ""; // random string of "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + letter;
        for(let i = 0; i < this.randNumAnimateFrom; i++){
            this.randCharacters += this.characterChoices.charAt(Math.floor(Math.random() * this.characterChoices.length));
        }
        this.randCharacters += letter;
    }

    update = (time) => {
        if(this.animationProgress < 1){
            if(!time) time = window.performance.now();
            let startTime = this.startAnimationTime;
            if (!startTime) startTime = time;
            this.animationProgress = Math.min((time - startTime) / this.animationDuration, 1);
            const easedProgress = Math.min(this.easeOutQuad(this.animationProgress), 1);
            const letterIndex = Math.floor(easedProgress * this.randNumAnimateFrom);
            this.letter = [...this.randCharacters][this.randCharacters.length - 1 - this.randNumAnimateFrom + letterIndex];
        }
    }

    easeOutQuad = function(t, b = 0, _c = 1, d = 1) {
        let c = _c - b;
        return -c * (t /= d) * (t - 2) + b;
    };
}

export default Letter;