import React, { useEffect, useState } from "react";
import HeaderNotFound from "../components/HeaderNotFound";
import NotFound from "../components/NotFound";
import FullHeight from "../components/layout/FullHeight";
import { Helmet } from "react-helmet"

const NotFoundPage = (props) => {
  const {pageContext, data} = props;
  const [pageComponent, setPageComponent] = useState(null);

  useEffect(() => {
    (async () => {
      if(data && pageContext['entryType']) {
        const template = await import(`../templates/${pageContext['entryType']}`);
        setPageComponent({Template: template.default});
      }
    })()
  }, [pageContext, data])

  if(pageComponent) {
    return <pageComponent.Template {...props} />;
  } else {
    return (
      <FullHeight>
        <Helmet title={"404: Not found"} />
        <HeaderNotFound theme="dark" />
        <NotFound theme="dark" />
      </FullHeight>
    )
  }

}

export default NotFoundPage
