import React, {useEffect, useState, useCallback} from "react";
import styles from "./NotFound.module.scss";
import LetterElement from "./LetterElement";
import Letter from "./Letter.js";
import {af as AF} from "@gladeye/af";
import Container from "../layout/Container";
import Row from "../layout/Row";
import Section from "../layout/Section";

const NotFound = ({theme}) => {
    const [letters, setLetters] = useState([]);
    const [lettersOriginal, setLettersOriginal] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const repeatedString = " 404 not found";
    const numCharsPerSentence = [...repeatedString].length;
    const numCharsPerRow = 40;
    const numRowsToMiddle = 8;
    const numRows = numRowsToMiddle * 2 - 1;
    const numCharsTotalFullRows = numCharsPerRow * numRowsToMiddle;
    const numCharsTotalReady = numCharsTotalFullRows - numCharsPerRow/2 - numCharsPerSentence/2;
    const numCharsOutBy = numCharsTotalReady % numCharsPerSentence;

    const numCharsPerRowMobile = 20;
    const numRowsToMiddleMobile = 8;
    const numRowsMobile = numRowsToMiddleMobile * 2 + 1;
    const numCharsToMiddleRowMobile = numRowsToMiddleMobile * numCharsPerRowMobile;
    const numCharsOutByMobile = numCharsPerRowMobile % numCharsPerSentence;
    const offsetMobile = numCharsPerSentence*0.5 + numCharsOutByMobile*0.5 + numCharsToMiddleRowMobile;

    const numCharsPerRowMobileLandscape = 20;
    const numRowsToMiddleMobileLandscape = 2;
    const numRowsMobileLandscape = numRowsToMiddleMobileLandscape * 2 + 1;
    const numCharsToMiddleRowMobileLandscape = numRowsToMiddleMobileLandscape * numCharsPerRowMobileLandscape;
    const numCharsOutByMobileLandscape = numCharsPerRowMobileLandscape % numCharsPerSentence;
    const offsetMobileLandscape = numCharsPerSentence*0.5 + numCharsOutByMobileLandscape*0.5 + numCharsToMiddleRowMobileLandscape;

    const af = AF();

    const run = useCallback(() => {
        const time = window.performance.now();
        
        const arrayLetters = lettersOriginal.map(letter => {
            if(time - letter.startAnimationTime < 5000 || letter.letter !== letter.letterOriginal){
                letter.update(time);
            }
            return letter;
        });
        setLetters(arrayLetters);
    },[lettersOriginal]);    

    useEffect(() => {
        if(loaded){
            af.addWrite(run);
        }

        return (() => {
            af.removeWrite(run);
        });
    },[run, loaded, af]);

    useEffect(() => {
        const content1 = [];
        const content2 = [];
        const title = [];

        for(let i = -numCharsOutBy + numCharsPerSentence; i < numCharsTotalReady - numCharsOutBy + numCharsPerSentence; i++){
            content1.push(new Letter([...repeatedString][i%numCharsPerSentence], false, i <= numCharsPerRow * 2));
        };
    
        for(let i = 0; i < numCharsTotalReady; i++){
            content2.push(new Letter([...repeatedString][i%numCharsPerSentence], false, i >= numCharsTotalReady - (numCharsPerRow * 2)));
        };
    
        for(let i = 0; i < numCharsPerSentence; i++){
            title.push(new Letter([...repeatedString][i%numCharsPerSentence], true));
        };

        setLetters([...content1, ...title, ...content2]);
        setLettersOriginal([...content1, ...title, ...content2]);

        setLoaded(true);
    },[numCharsOutBy, numCharsPerSentence, numCharsTotalReady, repeatedString]);

    const handleHover = (e) => {
        const {target} = e;
        const {id} = target.dataset;

        const rowOfId = Math.ceil((parseInt(id) + 1) / numCharsPerRow);

        for(let i = parseInt(id)-1; i <= parseInt(id)+1; i++){
            const rowOfI = Math.ceil((parseInt(i) + 1) / numCharsPerRow);
            if(letters[i] && rowOfId === rowOfI){
                letters[i].startAnimationTime = window.performance.now();
                letters[i].animationProgress = 0;
            }
        }
    };

    const lettersElements = letters.map((letter,i) => {
        const isNotCenterLineMobile = i < letters.length*0.5 - offsetMobile || i >= letters.length*0.5 + offsetMobile;
        const isNotCenterLineMobileLandscape = i < letters.length*0.5 - offsetMobileLandscape || i >= letters.length*0.5 + offsetMobileLandscape;
        
        return (<LetterElement
                key={i}
                letter={letter.letter}
                id={i}
                isWhite={letter.isWhite || ""}
                hiddenMobile={isNotCenterLineMobile}
                hiddenMobileLandscape={isNotCenterLineMobileLandscape}
                handleHover={handleHover}
            />);
    });

    return (
        <Section className={styles.notFound} theme={theme} style={{
                "--letters-per-row": numCharsPerRow,
                "--letters-per-column": numRows,
                "--letters-per-row-mobile": numCharsPerRowMobile,
                "--letters-per-column-mobile": numRowsMobile,
                "--letters-per-row-mobile-landscape": numCharsPerRowMobileLandscape,
                "--letters-per-column-mobile-landscape": numRowsMobileLandscape
            }}>
            <Container extended={true}>
                <Row>
                    <div className={styles.notFound__content}>
                        <p>{lettersElements}</p>
                    </div>
                </Row>
            </Container>
        </Section>
    );
};

export default NotFound;