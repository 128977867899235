import React from "react";
import styles from "./FullHeight.module.scss";

const FullHeight = ({test, children}) => {
    return (
        <div className={`${styles.fullHeight} ${test ? styles.test : ""}`}>
            {children}
        </div>
    );
};

export default FullHeight;