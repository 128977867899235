import React from "react";
import LogoAnimated from "../LogoAnimated";
import styles from "./HeaderNotFound.module.scss";
import { Link } from "gatsby";
import getColors from "../../util/getColors";
import GradientButton from "../elements/GradientButton";
import Row from "../layout/Row";

const HeaderNotFound = ({theme}) => {
    const themeColors = getColors(theme);

    return (
        <header className={styles.header} style={themeColors}>
            <Row verticalCenter={true} className={styles.row}>
                <div className={styles.header__logoContainer}>
                    <Link to="/" className={styles.header__logoContainer__logo}><LogoAnimated /></Link>
                </div>
                <div className={styles.header__navContainer}>
                    <GradientButton url="/" text="Back to homepage" altColors={true} header={true} buttonClassName={styles.button} />
                </div>
            </Row>
        </header>
    )
};

export default HeaderNotFound;