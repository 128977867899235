import React from "react";
import styles from "./LetterElement.module.scss";

const LetterElement = ({letter, id, isWhite, handleHover, hiddenMobile, hiddenMobileLandscape}) => {
    return (
        <span
            className={`${styles.letter} ${isWhite ? styles.white : ""} ${hiddenMobile ? styles.hiddenMobile : ""} ${hiddenMobileLandscape ? styles.hiddenMobileLandscape : ""}`}
            data-id={id}
            onMouseEnter={handleHover}
            role="presentation"
        >
            {letter}
        </span>
    );
};

export default LetterElement;